<template>
<div>
  <div class="full-width cart" v-if="$store.state.user.loggedIn">

    <div class="container m-b-5">

      <div class="col-12 align-center" style="display:flex;flex-direction:column;align-items:center;">
        <div class="full-width spacer no-mobi" ></div>
        <h3>I tuoi ordini <span @click="logout" class="bold-pointer">(Esci)</span></h3>

        <div class="firstTableMobile" v-if="orders.length" style="width:100%;max-width:60%;">
          <table class="orders-table" style="width:100%;max-width:100%;">
            <tr>
              <th>ID Ordine</th>
              <th>Data ordine</th>
              <th>Stato ordine</th>
              <th>Prezzo totale</th>
            </tr>


            <!--<tr v-for="(item, k) in orders" v-bind:key="k" @click="openModal(k, item.orderId)">
              <td>{{item.amJson.orderId}}</td>
              <td>{{$utils.formattedDate(item.amJson.orderDate)}}</td>
              <td>{{$utils.getTextStatus(item.status)}}</td>
              <td>{{$utils.orderTotal(item)}} &euro;</td>
              <div v-if="modalIsOpen === true">
                Ciao
              </div>
            </tr>-->
          </table>
        </div>
          <vsa-list>
            <vsa-item v-for="(item, k) in this.orders" v-bind:key="k">
              <vsa-heading>
                <table style="width:100%;font-size:15px;" @click="print(item)">
                  <tr>
                    <td width="150px">{{item.amJson.orderId}}</td>
                    <td width="182px">{{$utils.formattedDate(item.amJson.orderDate)}}</td>
                    <td width="189px" v-if="$utils.under30Min(item.amJson.orderDate)">In attesa</td>
                    <td width="189px" v-if="!$utils.under30Min(item.amJson.orderDate)">{{$utils.getTextStatus(item.status)}}</td>
                    <td width="199px">{{$utils.orderTotal(item)}} &euro; </td>
                  </tr>
                </table>
              </vsa-heading>

              <vsa-content>
                <table style="font-size:15px;text-align:center;">
                  <tr>
                    <th></th>
                    <th>Prodotto</th>
                    <th>Cod.</th>
                    <th>Q.tà</th>
                  </tr>
                  <tr v-for="(cart, i) in item.cart" v-bind:key="i">
                    <td width="10%" v-if="cart.item.images">
                      <img style="width:70px;" :src="'https://shop.altromercato.it/products/' + cart.item.images[0]" alt="">
                    </td>
                    <td width="10%" v-else>
                      <img style="width:70px;" src="https://temporitrovatolibri.it/wp-content/uploads/woocommerce-placeholder-600x600.png" alt="">
                    </td>
                    <td width="70%">{{cart.item.productName}}</td>
                    <td width="10%">{{cart.item.itemNumber}}</td>
                    <td width="10%">x{{cart.qty}}</td>
                  </tr>
                </table>
              </vsa-content>
            </vsa-item>
          </vsa-list>
      </div>
    </div>
  </div>

  <div class="full-width cart" v-if="!$store.state.user.loggedIn">
    <div class="container m-b-5">

      <div class="col-6 mobi-space p-r-20">

        <div class="full-width spacer no-mobi"></div>
        <h2 class="shipping-title">NON SEI ANCORA REGISTRATO?</h2>

        <p>Scopri il mondo Altromercato, registrati subito! Potrai completare rapidamente i tuoi acquisti, memorizzare il tuo indirizzo di spedizione e tenere traccia dei tuoi ordini.</p>

        <!--<p class="little-text">È obbligatorio creare nuovamente un account anche se ti sei registrato prima del 28/07/2021</p>-->
        <!--<p style="color: red">È obbligatorio creare nuovamente un account anche se ti sei registrato prima del 28/07/2021</p>-->

        <div class="row spaced">
          <div class="col-6">
            <input type="text" placeholder="Nome" v-model="register.name" />
          </div>
          <div class="col-6">
            <input type="text" placeholder="Cognome" v-model="register.surname" />
          </div>
        </div>

        <div class="row spaced">
          <div class="col-12">
            <input type="text" placeholder="Email" v-model="register.email" />
          </div>
        </div>

        <div class="row spaced">
          <div class="col-6">
            <input type="password" placeholder="Password" v-model="register.password" />
          </div>
          <div class="col-6">
            <input type="password" placeholder="Conferma password" v-model="register.confirm" />
          </div>
        </div>

        <div class="row spaced">
          <div class="col-12">
            Ho letto e accettato la <a href="https://www.altromercato.it/privacy-policy/" target="_blank">Privacy Policy</a> per il trattamento dei miei dati personali.
          </div>
        </div>
        <div class="row spaced">
          <div class="col-12">
            <input type="checkbox" v-model="register.newsletter" /> Acconsento al trattamento dei miei dati personali per finalità di marketing (quali newsletter, promozioni novità) come ai sensi dell'articolo 3 della <a href="/pages/politica-di-privacy" target="_blank">privacy policy</a>
          </div>
        </div>

        <div class="row spaced">
          <div class="col-12 align-center">
            <div class="button" @click="handleRegister()">Registrati</div>
          </div>
        </div>
      </div>

      <div class="col-6 mobi-space p-l-20">

        <div class="full-width spacer no-mobi"></div>
        <h2 class="shipping-title more-margin">SEI GIÀ REGISTRATO?</h2>

        <p>Inserisci il tuo indirizzo mail e la tua password e gestisci in modo semplice e veloce il tuo account.</p>

        <div class="row spaced">
          <div class="col-12">
            <input type="text" placeholder="Email" v-model="login.email" />
          </div>
        </div>
        <div class="row spaced">
          <div class="col-12">
            <input type="password" placeholder="Password" v-model="login.password" />
          </div>
        </div>
        <div class="row spaced">
          <div class="col-12" style>
            <router-link to="/password-dimenticata">Hai dimenticato la password?</router-link>
          </div>
        </div>

        <div class="row spaced">
          <div class="col-12 align-center">
            <div class="button strange-button" @click="handleLogin()">Entra</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';

export default {
  name: 'Account',
  components : {
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,

  },
  metaInfo: {
    title: 'Mio Account'
  },
  data: function () {
    return {
      isRegistering: false,
      register: {
        name: '',
        surname: '',
        email: '',
        password: '',
        confirm: '',
        newsletter: false
      },
      login: {
        email: '',
        password: ''
      },
      orders: [],
      modalIsOpen: false
    }
  },

  watch: {
    '$store.state': function () {
      this.getOrders()
    }
  },

  async mounted () {
    await this.getOrders()
  },

  methods: {
    async getOrders() {
      if (this.$store.state.user.loggedIn) {
        try {
          let resp = await axios.get(this.$config.backendUrl + 'index.php?action=get_orders&email=' + this.$store.state.user.data.email)
          this.orders = resp.data
          console.log(this.orders)
        } catch (e) {
          console.log(e)
        }
      }
    },
    async handleRegister () {
      if(this.isRegistering) return
      this.isRegistering = true
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (this.register.name.length < 3) {
        this.$toast.open('Il campo nome è obbligatorio')
        this.isRegistering = false
        return false
      }

      if (this.register.surname.length < 3) {
        this.$toast.open('Il campo cognome è obbligatorio')
        this.isRegistering = false
        return false
      }

      if (!re.test(String(this.register.email).toLowerCase())) {
        this.$toast.open('Il campo email non è valido')
        this.isRegistering = false
        return false
      }

      if (this.register.password.length < 8) {
        this.$toast.open('Inserisci una password di almeno 8 caratteri')
        this.isRegistering = false
        return false
      }

      if (this.register.confirm != this.register.password) {
        this.$toast.open('Le due password devono coincidere')
        this.isRegistering = false
        return false
      }

      try {
        let resp = await axios.post(this.$config.baseUrl + 'register.php', JSON.stringify(this.register))

        if (resp.data.status == 'KO') {
          this.$toast.open('Questa mail è già stata utilizzata per registrarsi')
          this.isRegistering = false
        } else {
          await axios.post(this.$config.backendUrl + 'index.php?action=register', JSON.stringify(this.register))
          this.$toast.open('Ti sei registrato con successo, procedi con gli acquisti!')

          this.$store.commit('login', this.register)
          location.reload()
        }
      } catch (e) {
        console.log(e)
        this.$toast.open('Qualcosa è andato storto! Riprova più tardi')
        this.isRegistering = false
      }
    },

    async handleLogin () {
      try {
        let resp = await axios.post(this.$config.baseUrl + 'login.php', JSON.stringify(this.login))
        if (resp.data.status == 'KO') {
          this.$toast.open('Controlla le credenziali inserite')
        } else {
          this.$store.commit('login', resp.data.data)
          if(resp.data.data && resp.data.data.idwebtracking && window.mna && window.mna.setContactId){
            window.mna.setContactId(resp.data.data.idwebtracking);
          }
          location.reload()
        }
      } catch (e) {
        console.log(e)
        this.$toast.open('Controlla le credenziali inserite')
      }
    },

    logout () {
      this.$store.commit('logout')
    },
    print (item) {
      console.log(item )
    }
  }
}
</script>

<style>
@media (max-width:768px){
  .vsa-list, .firstTableMobile{
    max-width: 90%!important;
  }
}
@media (min-width:768px) and (max-width:1024px){
  .vsa-list, .firstTableMobile{
    max-width: 90%!important;
  }
}
@media(min-width: 1024px){
  .vsa-list, .firstTableMobile{
    max-width: 60%!important;
  }
}
.vsa-item__trigger:focus, .vsa-item__trigger:hover{
  background-color: #000!important;
  color: white!important  ;
}
.vsa-item__trigger{
  padding: 10px 0!important;
}
.vsa-item__trigger__icon{
  display: none!important;
}
.vsa-item__trigger__content{
  width: 100%;
}
.cart h1 {
  font-weight: 600;
  font-size: 38px;
  margin: 40px 0;
  width: 100%;
}

.cart-table {
  width: 100%;
  border-style:none none solid;
  border-collapse: collapse;
  margin-bottom: 46px;
  border: 0px solid black;
}

.cart .container.column {
  flex-direction: column;
}

thead tr {
  border-bottom: 1px solid #cfcfcf;
  height: 40px;
  line-height: 40px;
}

thead th {
  text-align: left;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
}

tbody tr {
  height: 120px;
  border-bottom: 1px solid #cfcfcf;
}

.cart-image img {
  width: 90px;
  height: 90px;
  border: 1px solid #eee;
}

.cart-name {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.3px;
}

.cart-discount {
  display: block;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 3px;
}

.prod-qty {
  display: inline-block;
  margin-right: 15px;
}

.fa-trash {
  cursor: pointer;
}

.coupon-code {
  background-color: #fff;
  text-align: center;
  height: 32px;
  line-height: 32px;
  width: 100%;
  border: 1px solid #e3e3e3;
  border-radius: 16px;
}

.button {
  height: 32px;
  line-height: 32px;
  font-size: 12px;
}

.coupon-description {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  letter-spacing: 0.5px;
}

hr {
  border: 1px solid #e3e3e3;
}

.subtotal-row {
  height: 32px;
  line-height: 32px;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.totals {
  padding-left: 40px;
}

.interested-in {
  font-weight: 500;
  font-size: 23px;
  letter-spacing: 0.3px;
}

input[type="text"],
input[type="password"]
{
  width: 100%;
  margin-right: 15px;
  height: 32px;
  line-height: 32px;
  padding-left: 10px;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ddd;
  border-radius: 16px;
}

.row.spaced {
  margin-bottom: 20px;
}

.row.spaced .col-6,
.row.spaced .col-12 {
  padding-right: 15px;
  font-family: neue-haas-grotesk-display, sans-serif;
}

.payment-method {
  margin-top: 125px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: neue-haas-grotesk-display, sans-serif;
  text-transform: uppercase;
}

.fattura {
  margin-bottom: 60px;
}

.shipping-title {
  margin: 0 0 10px 0;
}

.shipping-title + hr {
  margin-bottom: 20px;
}

p {
  line-height: 140%;
  max-width: 90%;
}

p.little-text {
  font-size: 13px;
}

h2 {
  font-size: 28px !important;
}

.button.strange-button {
  border: 3px solid #000;
  background-color: #fff;
  color: #000;
  line-height: 25px;
}

.button.strange-button:hover {
  color: #000 !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.orders-table {
  width: 50%;
  margin: 0 auto;
  border-collapse: collapse;
}

.orders-table th {
  background-color: #eee;
  padding: 10px 0;
}

.orders-table td {
  height: 30px;
}

@media (max-width: 680px) {

  body .p-r-20 {
    padding-right: 0px !important;
  }

  body .p-l-20 {
    padding-left: 0px !important;
  }

  .shipping-title {
    margin-top: 25px;
    text-align: center;
    margin-bottom: 25px;
  }

  .shipping-title.more-margin {
    margin-top: 50px;
  }

  .row.spaced {
    display: flex;
    justify-content: space-between;
  }

  .row.spaced .col-6,
  .row.spaced .col-12 {
    padding-right: 0;
  }

  .row.spaced .col-6 {
    width: 48%;
  }
}
</style>


